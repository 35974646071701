
import { RequestPaging } from "@/utils/util";
import { Component, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
import { apiCategoryList } from "@/api/model";
import PopoverInput from "@/components/popover-input.vue";

@Component({
  components: {
    LsDialog,
    LsPagination,
    PopoverInput,
  },
})
export default class ModelCategory extends Vue {
  $refs!: { plTreeTable: any };
  pager = new RequestPaging();
  categoryList: any = [];

  getList(): void {
    apiCategoryList({}).then((res) => {
      this.categoryList = res.category_list;
    });
  }
  handleStatus(value: number, id: number) {
    // apiModelCategoryStatus({
    //   id,
    //   is_show: value,
    // }).then(() => {
    //   this.getList();
    // });
  }
  handleSort(sort: string, id: number) {
    // apiModelCategorySort({
    //   id,
    //   sort,
    // }).then(() => {
    //   this.getList();
    // });
  }
  handleDelete(id: number) {
    // apiModelCategoryDel(id).then(() => {
    //   this.getList();
    // });
  }

  created() {
    this.getList();
  }
}
